<template>
  <div>
    <el-button
      type="success"
      icon="el-icon-refresh-left"
      circle
      size="mini"
      style="margin: 5px"
      @click="refresh"
    ></el-button
    >刷新
    <el-button v-if="scheduleData.length===0" type="warning" @click="ballot">一键抽取</el-button>
    <el-table
      height="50vh"
      :data="scheduleData"
      border
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column
        prop="opentime"
        label="开始时间"
        :formatter="formatterTime"
        width="200"
      >
      </el-table-column>
      <el-table-column prop="team1_name" label="主场战队" width="150">
      </el-table-column>
      <el-table-column prop="team2_name" label="客场战队" width="150">
      </el-table-column>
      <el-table-column prop="commentary" label="解说" width="150">
      </el-table-column>
      <el-table-column prop="referee" label="导播" width="150">
      </el-table-column>
      <el-table-column label="回放链接" width="150">
        <template slot-scope="scope">
            <a style="text-decoration:underline" :href="scope.row.bilibiliurl">点击前往</a>
        </template>
      </el-table-column>
  
      <!-- 2023-07-23T13:00:00 -->
      <el-table-column label="操作" width="auto" align="center">
        <template slot-scope="scope">
          <el-button @click="updateGame(scope.row)" type="primary" size="small"
            >更新</el-button
          >
          <el-button @click="delGame(scope.row)" type="danger" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 表单 -->
    <el-dialog title="赛程信息" :visible.sync="dialogFormVisible">
      <el-form :model="diaData">
        <el-row>
          <el-col :span="10">
            <el-form-item label="开始时间" :label-width="formLabelWidth">
              <el-input
                v-model="diaData.opentime"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="结束时间" :label-width="formLabelWidth">
              <el-input
                v-model="diaData.closetime"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="主场战队" :label-width="formLabelWidth">
              <el-input
                v-model="diaData.team1_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="客场战队" :label-width="formLabelWidth">
              <el-input
                v-model="diaData.team2_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="解说1" :label-width="formLabelWidth">
              <el-input
                v-model="diaData.commentary"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="解说2" :label-width="formLabelWidth">
              <el-input
                v-model="diaData.commentary"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="裁判" v-model="diaData.player" :label-width="formLabelWidth">
              <el-input
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="导播" :label-width="formLabelWidth">
              <el-input
                v-model="diaData.referee"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
        <el-form-item label="赛选获胜队伍" :label-width="formLabelWidth">
          <el-select v-model="candidate" placeholder="请选择获胜队伍">
            <el-option :label="diaData.team1_name" value="candidate[0]"></el-option>
            <el-option :label="diaData.team2_name" value="candidate[1]"></el-option>
          </el-select>
        </el-form-item>
        </el-row>
      
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSchedule">确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { delSchedule, getSchedule,ballotSchedule,updateSchedule } from "@/api/schedule/index";
export default {
  name: "ScheduleTable",
  data() {
    return {
      scheduleData: [],
      loading: false,
      dialogFormVisible: false,
      formLabelWidth:'200',
      diaData:[],
      candidate:[],
      currentPage:1,
      pageSize:5,
      total:null,
    };
  },
  methods: {
    // <!-- 2023-07-23T13:00:00 -->
    formatterTime(row, column, cellValue) {
      let rec = "";
      let year = cellValue.slice(0, 4);
      let month = cellValue.slice(5, 7);
      let day = cellValue.slice(8, 10);
      let time = cellValue.slice(11, 19);
      rec = year + "年" + month + "月" + day + "日" + " " + time;
      return rec;
    },
    //获取赛程
    initSchedule(page,pagesize) {
      this.loading = true;
      getSchedule(page,pagesize)
        .then((res) => {
          console.log(res.data);
          this.scheduleData = res.data;
          this.total = res.data.length
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    delGame(row) {
      delSchedule(row.id)
        .then(() => {
          this.$message.success("删除成功！");
          this.initSchedule(this.currentPage,this.pageSize);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    refresh() {
      this.initSchedule(this.currentPage,this.pageSize);
    },
    updateGame(row) {
      this.dialogFormVisible = true;
      this.diaData = row
      this.candidate.push(row.team1_name)
      this.candidate.push(row.team2_name)
    },
    updateSchedule(){
      const info = {
        team1_name:this.diaData.team1_name,
        team2_name:this.diaData.team2_name,
        opentime:this.diaData.opentime,
        commentary:this.diaData.commentary,
        referee:this.diaData.referee
      }
      updateSchedule(this.diaData.id,info)
      .then(res=>{
        console.log(res.data)
        this.dialogFormVisible = false
        this.initSchedule(this.currentPage,this.pageSize);
      })
      .catch(err=>{
        console.log(err)
      })

    },
    //抽签赛程
    ballot(){
      ballotSchedule()
      .then(res=>{
        console.log(res.data)
        this.initSchedule(this.currentPage,this.pageSize)
      })
      .catch(err=>{
        console.log(err)
      })
    },
    handleCurrentChange(val){
       this.currentPage = val
       this.initSchedule(this.currentPage,this.pageSize);
    }
  },
  mounted() {
    this.initSchedule(this.currentPage,this.pageSize);
  },
};
</script>

<style scoped lang="less">
  .el-col{
    margin:0 10px;
  }
</style>
